import packageJson from '../../package.json';

export const environment = {
  version: packageJson.version,
  production: true,
  isProduction: false,
  isniPath: 'api/isni',
  isrcPath: 'api/isrc',
  bowiPath: 'api/bowi',
  stripePath: 'api/stripe',
  clientPath: 'api/auth',
  emailPath: 'api/email',
  stripe: {
    products : {
      registrationIsni: {
        product_id: "prod_QclaCA1WA45IWz",
        price_id: "price_1PlW33A6LnrfB826KzxuvqJb",
        price: 1000
      },
      registrationIsrc: {
        product_id: "prod_QclZtIZ9Gwf2YB",
        price_id: "price_1PlW2KA6LnrfB8260xIk56y5",
        price: 0
      },
      registrationBowi: {
        product_id: "prod_QclYOuzaK8bxQg",
        price_id: "price_1PlW1NA6LnrfB826IfC036F9",
        price: 0
      }
    }
  }
};
