import { Routes } from '@angular/router';
import { WhiteLabelService } from './app/services/white-label.service';


export const routes: Routes = [
  {path: '', redirectTo: '/app-login', pathMatch: 'full'},
  {path: 'app-login', loadComponent: () => import('./app/app-login/app-login.component').then(c => c.AppLoginComponent) },
  {path: 'app-home', loadComponent: () => import('./app/app-home/app-home.component').then(c => c.AppHomeComponent) },
  {path: 'opusmusic', 
  loadComponent: () => import('./app/app-home/app-home.component').then(c => c.AppHomeComponent),
  canActivate:  [WhiteLabelService], data: {id: 'opusmusic'}},
  {path: 'vevasound', 
  loadComponent: () => import('./app/app-home/app-home.component').then(c => c.AppHomeComponent),
  canActivate:  [WhiteLabelService], data: {id: 'vevasound'}},
  {path: 'bridger', 
  loadComponent: () => import('./app/app-home/app-home.component').then(c => c.AppHomeComponent),
  canActivate:  [WhiteLabelService], data: {id: 'bridger'}},
  {path: 'lorelai-lab', 
  loadComponent: () => import('./app/app-home/app-home.component').then(c => c.AppHomeComponent),
   canActivate:  [WhiteLabelService], data: {id: 'lorelailab'}},
  {path: 'metamusique', 
  loadComponent: () => import('./app/app-home/app-home.component').then(c => c.AppHomeComponent),
   canActivate:  [WhiteLabelService], data: {id: 'metamusique'}},
 
  {path: 'app-bowi', loadComponent: () => import('./app/app-bowi/app-bowi.component').then(c => c.AppBowiComponent) },
  {path: 'app-isni', loadComponent: () => import('./app/app-isni/app-isni.component').then(c => c.AppIsniComponent) },
  {path: 'app-isni-person', loadComponent: () => import('./app/app-isni-person/app-isni-person.component').then(c => c.AppIsniPersonComponent) },
  {path: 'app-isni-band', loadComponent: () => import('./app/app-isni-band/app-isni-band.component').then(c => c.AppIsniBandComponent) },
  {path: 'app-isni-label', loadComponent: () => import('./app/app-isni-label/app-isni-label.component').then(c => c.AppIsniLabelComponent) },
  {path: 'app-isrc', loadComponent: () => import('./app/app-isrc/app-isrc.component').then(c => c.AppIsrcComponent) },
  {path: 'app-cart', loadComponent: () => import('./app/app-cart/app-cart.component').then(c => c.AppCartComponent) },
  {path: 'app-faq', loadComponent: () => import('./app/app-faq/app-faq.component').then(c => c.AppFaqComponent) },
  {path: "app-checkout-success", loadComponent: () => import('./app/app-checkout-success/app-checkout-success.component').then(c => c.AppCheckoutSuccessComponent) },
  {path: "app-checkout-cancel", loadComponent: () => import('./app/app-checkout-cancel/app-checkout-cancel.component').then(c => c.AppCheckoutCancelComponent) },
  {path: "app-test-ts", loadComponent: () => import('./app/app-test/app-test.component').then(c => c.AppTestComponent) },
]
