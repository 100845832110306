import { Injectable, inject } from '@angular/core';
import { PriceService } from './price.service';
import {
  BowiRegistrationRequest,
  CartItem,
  IsniRegistrationRequest,
  IsrcRegistrationRequest,
} from './model';
import {Subject} from "rxjs";
import {environment} from "../../environments/environment";



@Injectable({
  providedIn: 'root'
})
export class CartService {
  priceService = inject(PriceService);

  items: CartItem[] = [];

  cartItemsSubject:Subject<CartItem[]> = new Subject();

  isFree = false;

  constructor() {
    this.items = JSON.parse(localStorage.getItem("QUANSIC_CART") || "[]");
    this.cartItemsSubject.next(this.items);
    this.priceService.isFree$.subscribe({
      next: isFree => this.isFree = isFree
    })
  }

  addIsniRegistration(request:IsniRegistrationRequest){
    request._req = "isni";
    const cartItem:CartItem = {
      id: this.generateId(),
      request: request,
      currency: 'eur'
    }
    // if(this.loginService.isAuthenticated()){
      // cartItem.request.clientKey = this.loginService.getRegistrationKey()||undefined;
    // }
    if(this.isFree) {
      cartItem.price = 0
    } else {
      const stripeInfo = environment.stripe.products.registrationIsni;
      cartItem.priceId = stripeInfo.price_id
      cartItem.productId = stripeInfo.product_id
      cartItem.price = stripeInfo.price
    }
    return this.addCartItem(cartItem)
  }

  addIsrcRegistration(request:IsrcRegistrationRequest){
    request._req = "isrc";
    const cartItem:CartItem = {
      id: this.generateId(),
      request: request,
      currency: 'eur'
    }
    // if(this.loginService.isAuthenticated()){
    //   cartItem.request.clientKey = this.loginService.getRegistrationKey()||undefined;
    // }
    if(this.isFree) {
      cartItem.price = 0
    } else {
      const stripeInfo = environment.stripe.products.registrationIsrc;
      cartItem.priceId = stripeInfo.price_id
      cartItem.productId = stripeInfo.product_id
      cartItem.price = stripeInfo.price
    }
    return this.addCartItem(cartItem)
  }

  addBowiRegistration(request:BowiRegistrationRequest){
    request._req = "bowi";
    const cartItem:CartItem = {
      id: this.generateId(),
      request: request,
      currency: 'eur'
    }
    // if(this.loginService.isAuthenticated()){
    //   cartItem.request.clientKey = this.loginService.getRegistrationKey()||undefined;
    // }
    if(this.isFree) {
      cartItem.price = 0
    } else {
      const stripeInfo = environment.stripe.products.registrationBowi;
      cartItem.priceId = stripeInfo.price_id
      cartItem.productId = stripeInfo.product_id
      cartItem.price = stripeInfo.price
    }
    return this.addCartItem(cartItem)
  }


  generateId() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  addCartItem(line:CartItem) {
    this.items.push(line)
    this.syncCart();
    return true;
  }

  removeCartItem(id:string){
    this.items = this.items.filter((item) => item.id != id);
    this.syncCart();
  }

  resetCart(){
    this.items = [];
    this.syncCart();
  }

  syncCart(){
    this.cartItemsSubject.next(this.items);
    localStorage.setItem("QUANSIC_CART", JSON.stringify(this.items));
  }

  getCartItems(){
    return this.items;
  }

  getCartItemsObservable() {
    return this.cartItemsSubject.asObservable();
  }}
