import { bootstrapApplication } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavbarComponent } from './app/navbar/navbar.component';
import { appConfig } from './app.config';
import { LocalizationDirective } from './app/shared/localization/localization.directive';
import "@angular/compiler"

@Component({
  standalone: true,
  selector: 'app-root',
  template: `    
  <app-navbar></app-navbar>
  <div class="container mat-typography">
    <router-outlet></router-outlet>
  </div>
  `,
  imports: [CommonModule, RouterOutlet, NavbarComponent, LocalizationDirective]
})
export class AppComponent {
  title = 'registerator';
}

// if (environment.production) {
//   enableProdMode();
// }

bootstrapApplication(AppComponent, appConfig)
.catch(err => console.error(err));
