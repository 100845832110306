import { LocalizationService } from './../localization/localization.services';
import { Component, inject } from "@angular/core";
import { SupportedLocale } from "../localization/localization.services";
import { NgIf, AsyncPipe, NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl } from '@angular/forms';
import { tap } from 'rxjs';

declare const window:any;

@Component({
  standalone: true,
  selector: 'locale-selector',
  templateUrl: './locale-selector.component.html',
  styleUrls: ['./locale-selector.component.scss'],
  imports: [NgIf, NgFor, AsyncPipe, MatFormFieldModule, MatSelectModule ]
})
export class LocaleSelectorComponent {
  localizationService = inject(LocalizationService)

  SuppportedLocale = SupportedLocale

  currentLocale$ = this.localizationService.currentLocale$

  selectedLocale: SupportedLocale|null = null;

  constructor() {
    this.currentLocale$.pipe(
      tap(currentLocale => this.selectedLocale = currentLocale)
    ).subscribe();
  }

  selectLocale(){
    if(this.selectedLocale !== null) {
      this.localizationService.loadLocale(this.selectedLocale).subscribe({
        next: () => window.location.href = `${window.location.origin}/app-home`
      });
    }
  }

  changeLocaleTo(locale: SupportedLocale){
    this.localizationService.loadLocale(locale).subscribe({
      next: () => window.location.href = `${window.location.origin}/app-home`
    });
  }

  locales() {
    return Object.values(SupportedLocale);
  }

  flag(locale: SupportedLocale){
    switch(locale){
      default:
      case SupportedLocale.EN: return "us";
      case SupportedLocale.JA: return "jp";
      case SupportedLocale.FR: return "fr";
    }
  }
}