import { RegisRole } from '@githubquansic/auth/built/client';
import {Component, inject, Input, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import { CommonModule } from '@angular/common';
import { LocalizationDirective } from '../shared/localization/localization.directive';
import { WhiteLabelService } from '../services/white-label.service';
import { LoginService } from '@githubquansic/web-auth/ng'; 
import { EMPTY, map, merge, Observable, of, switchMap, tap } from 'rxjs';
import { PriceService } from '../services/price.service';

@Component({
  standalone: true,
  selector: 'price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.css'],
  imports: [CommonModule, LocalizationDirective ]
})
export class PriceComponent implements OnInit {
  priceService = inject(PriceService);
  
  @Input('product') product: string = "";


  ngOnInit(): void {
    this.setup();
  }

  isFree$: Observable<boolean> = this.priceService.isFree$;
  price$: Observable<number> = EMPTY
  setup() {
    this.price$ = this.priceService.price$(this.product);
  }
}
