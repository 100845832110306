import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {CartService} from "../services/cart.service";
import {LoginService, QuansicWebAuthModule} from "@githubquansic/web-auth/ng";
import {Whitelabel} from '../services/model';
import {environment} from '../../environments/environment';
import {ModalContactUsComponent} from '../modal-contact-us/modal-contact-us.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { WhiteLabelService} from '../services/white-label.service';
import { RouterLink } from '@angular/router';
import { PriceComponent } from '../price/price.component';
import { LocalizationDirective } from '../shared/localization/localization.directive';
import { LocaleSelectorComponent } from '../shared/locale-selector/locale-selector.component';

@Component({
    standalone: true,
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
    imports: [CommonModule, RouterLink, QuansicWebAuthModule, 
        MatMenuModule, MatBadgeModule, MatButtonModule, MatIconModule, MatToolbarModule,
        MatDialogModule, MatSnackBarModule, 
        PriceComponent, LocaleSelectorComponent, LocalizationDirective]
})
export class NavbarComponent implements OnInit {
    private cartService = inject(CartService)
    private loginService = inject(LoginService)
    public whitelabelService = inject(WhiteLabelService)
    public dialog = inject(MatDialog)
    private snackBar = inject(MatSnackBar)

    cartItemsCount:number = 0;
    clientGraphic:any;
    version:string = environment.version;

    isProduction:boolean = false;

  	constructor(){
        this.isProduction = environment.isProduction;
  	}

  	ngOnInit(): void {
        this.cartItemsCount = this.cartService.getCartItems().length;
        this.initCartSubscriber();
        this.initLoginSubscriber();
  	}

    initCartSubscriber() {
        this.cartService.getCartItemsObservable().subscribe(items => {
            this.cartItemsCount = items.length;
        })
    }

    initLoginSubscriber() {
        this.handleClientSpecificTheme();
        this.loginService.login$.subscribe(() => {
            this.handleClientSpecificTheme();
        })
    }

    handleClientSpecificTheme() {
        this.whitelabelService.whitelabels$.subscribe((wl: Whitelabel) => {
            if(wl !== null) this.clientGraphic = wl;
        })
    }


    logout() {
        this.loginService.logout().subscribe();
    }
    
    isAuthenticated() {
      return this.loginService.isAuthenticated(); 
    }
    getUserName():string|undefined {
        return this.loginService.getUserData()?.name;
    }

    getUserFirstLetter():string|undefined {
        return this.getUserName()?.substr(0,1)
    }

    openContactUsDialog(){
        const dialogRef = this.dialog.open(ModalContactUsComponent);
        dialogRef.afterClosed().subscribe((messageSent:boolean) => {
            if(messageSent) {
                let snackBarRef = this.snackBar.open("Message sent successfully", "Ok")
                snackBarRef.onAction().subscribe(() => {
                    snackBarRef.dismiss();
                });
            }
        })
    }
}
