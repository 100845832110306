import { RegisRole } from '@githubquansic/auth/built/client';
import { Injectable, inject } from "@angular/core";
import { LoginService } from "@githubquansic/web-auth/ng";
import { merge, map, tap, of, switchMap } from "rxjs";
import { WhiteLabelService } from "./white-label.service";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PriceService {
  private whitelabelService = inject(WhiteLabelService)
  private loginService = inject(LoginService)
  
  isFree$ = merge(
    this.whitelabelService.hasFreeAccess$(),
    this.loginService.login$.pipe(
      map(userData => {
        //If we are in a case of a free whiteservice, don't bother looking into the userdata.
        if(this.whitelabelService.whitelabel?.access == "free") return true;
        if(this.whitelabelService.whitelabel?.access == "login")
          return userData?.rights?.regisRoles?.includes(RegisRole.Free) || false
        return false;
      }),
    ),
    this.loginService.logout$.pipe(map(() => false))
  );

  price$(product: string){
    return of(product).pipe(
      map(product => {
        switch(product) {
          case "isni":
            return environment.stripe.products.registrationIsni.price;
          case "isrc":
            return environment.stripe.products.registrationIsrc.price;
          case "bowi":
            return environment.stripe.products.registrationBowi.price;
          default:
            return 0
        }
      }),
      switchMap(price => this.isFree$.pipe(map(isFree => isFree ? 0 : price / 100)))
    )
  }
}