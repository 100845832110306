import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EmailService {
    // private bffApiUrl = environment.apiBaseUrl;  // URL to web api
    origin = ""
    private emailPath = environment.emailPath;

    constructor(private http:HttpClient, @Inject(DOCUMENT) private document: Document) {
        this.origin = `${document.location.origin}`;
    }

    sendContactUsMessage(message:any) {
        return new Observable((subscriber) => {
            this.http.post(`${this.origin}/${this.emailPath}/sendContactUsMessage`, message).subscribe(
                (response:any) => {
                    subscriber.next();
                }
            )
        })
    }
}
