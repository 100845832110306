import { RegisRole } from '@githubquansic/auth/built/client';
import {Inject, Injectable, inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {Whitelabel} from './model';
import {clone} from 'lodash';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {LoginService, UserData} from "@githubquansic/web-auth/ng";
import {catchError, map, share, shareReplay, switchMap, tap} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';


const WHITELABELS: Map<string, Whitelabel> = new Map([
    ["default",
        {
            id: "quansic",
            access: 'login',
            poweredByQuansic: false,
            logo: "quansic/quansic.png",
            name: "quansic/quansic_name_dark.png"
        }],

    ["bridger",
        {
            id: "bridger",
            access: 'free',
            poweredByQuansic: true,
            name: "bridger/bridger.png"
        }],

    ["vevasound",
        {
            id: "vevasound",
            access: 'free',
            poweredByQuansic: true,
            logo: "veva/veva.png",
            name: "veva/veva_name_dark.png",
            confirmation: {
                isni: "fairchild@vevasound.com",
                bowi: "fairchild@vevasound.com"
            }
        }],
    ["opusmusic",
        {
            id: "opusmusic",
            access: 'free',
            poweredByQuansic: true,
            logo: "opusmusic/OpusMusicLogo.png",
        }],
    ["lorelailab",
    {
        id: "lorelailab",
        access: 'free',
        poweredByQuansic: true,
        logo: "lorelailab/lorelailab.png",
        confirmation: {
            isni: "aurelie@lorelai-lab.com",
            bowi: "aurelie@lorelai-lab.com"
        }
    }],
    ["metamusique",
    {
        id: "metamusique",
        poweredByQuansic: true,
        access: 'free',
        logo: "metamusic/metamusic.png",
        confirmation: {
            isni: "aurelie@quansic.com",
            bowi: "aurelie@quansic.com"
        }
    }]
]);



@Injectable({
    providedIn: 'root'
})
export class WhiteLabelService {
    loginService = inject(LoginService);
    private origin = ""
    private whiteLabels: Map<string, Whitelabel> = new Map(WHITELABELS);

    whitelabels$: Subject<any>;
    whitelabel:Whitelabel|undefined;

    constructor(private http:HttpClient, @Inject(DOCUMENT) private document: Document) {
        this.origin = `${document.location.origin}`;
        this.whitelabels$ = new BehaviorSubject(this.default());
        this.restoreDefault();
        const userData: UserData = this.loginService.getUserData() as UserData;

        if(this.loginService.isAuthenticated() && typeof userData?.whitelabelId !== "undefined")
            this.applyWhiteLabel(userData?.whitelabelId as string);

        this.loginService.login$.subscribe((userData:UserData) => {
            const ud = userData as UserData;
            if(ud.whitelabelId) { this.applyWhiteLabel(ud.whitelabelId); }
            //else { this.applyWhiteLabel( "default") }
        })

        this.loginService.logout$.subscribe(() => {
            console.log("this.loginService.logout$.subscribe")
            this.restoreDefault();
        })
    }

    default(): Whitelabel|undefined {
        return clone(this.whiteLabels.get("default"));
    }

    applyWhiteLabel(whitelabelId: string) {
        const wl: Whitelabel|undefined = this.whiteLabels.get(whitelabelId);
        if(typeof wl !== "undefined"){
            this.whitelabel = wl;
            this.whitelabels$.next(clone(wl));
        }
    }

    restoreDefault(): void {
        this.applyWhiteLabel("default");
    }

    /**
     * Call BFF to get details about the given whitelabel id, including the apiKey, and use it to log in
     * If success, apply whitelabel details and return true to continue page loading
     * If failure, return treu to continue page loading without authentifying and loading whitelabel
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const wlId:string = route.data.id as string;
        return this.http.get(`${this.origin}/api/whitelabel/id/${wlId}`).pipe(
            map((result:any) => result.apiKey),
            switchMap((apiKey:string) => this.loginService.login(apiKey)),
            tap((userData:any) => this.applyWhiteLabel(userData.wlId)),
            map((userData) => true),
            catchError((error) => {
                //login failed, continue page loading without applying whitelabel
                return of(true);
            })
        )
    }

    isUserVevaCollect() {
      return (this.loginService.getUserData() as UserData)?.whitelabelId === "vevasound";
    }

    isUserBridger() {
        return (this.loginService.getUserData() as UserData)?.whitelabelId === "bridger";
    }

    isUserOpusMusic() {
        return (this.loginService.getUserData() as UserData)?.whitelabelId === "opusmusic";
    }

    isUserLorelaiLab() {
        return (this.loginService.getUserData() as UserData)?.whitelabelId === "lorelailab";
    }

    isUserMetaMusique() {
        return (this.loginService.getUserData() as UserData)?.whitelabelId === "metamusique";
    }

    hasFreeAccess$(): Observable<boolean>{
        return this.whitelabels$.pipe(
            map((whitelabel => whitelabel?.access)),
            switchMap((access:string|undefined) => {
                if(access === undefined) return of(false)
                if(access === 'free') return of(true);
                else if(access === 'login') 
                     return of(this.loginService.getUserData()?.rights?.regisRoles?.includes(RegisRole.Free) || false);
                else return of(false)
           })
        )
    }
}
