import { provideHttpClient } from "@angular/common/http";
import { APP_INITIALIZER, importProvidersFrom } from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import { routes } from "./app-routes";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { LocalizationService, SupportedLocale } from "./app/shared/localization/localization.services";
import { lastValueFrom } from "rxjs";


const initializeLocalization = (localizationService: LocalizationService): Function => {
  return (): Promise<SupportedLocale> => lastValueFrom(localizationService.loadDefaultLocale());
}

export const appConfig = {
  providers: [
    provideHttpClient(),
    {
      provide: APP_INITIALIZER, 
      useFactory: initializeLocalization, 
      deps:[LocalizationService],
      multi: true
    },
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    provideRouter(routes),
    provideAnimations()
  ]
}