import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {EmailService} from '../services/email.service';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog'
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { LocalizationDirective } from '../shared/localization/localization.directive';

@Component({
    standalone: true,
    selector: 'app-modal-contact-us',
    templateUrl: './modal-contact-us.component.html',
    styleUrls: ['./modal-contact-us.component.css'],
    imports: [CommonModule, ReactiveFormsModule, MatDialogModule, MatFormFieldModule, 
        MatInputModule, MatButtonModule, MatDividerModule, MatProgressSpinnerModule,
        MatCardModule, LocalizationDirective]
})
export class ModalContactUsComponent implements OnInit {
    inProgress:boolean = false;
    completed:boolean = false;
    error:string|null = null;

    contactForm:FormGroup = new FormGroup({
        email: new FormControl('', [Validators.email, Validators.required]),
        name: new FormControl('', Validators.required),
        company: new FormControl(''),
        message: new FormControl('')
    })

    constructor(private dialogRef: MatDialogRef<ModalContactUsComponent>, private emailService:EmailService) { }

    ngOnInit(): void {
    }

    sendMessage() {
        const message = {
            email: this.contactForm.get('email')?.value,
            name: this.contactForm.get('name')?.value,
            company: this.contactForm.get('company')?.value,
            message: this.contactForm.get('message')?.value,
        }
        this.inProgress = true;
        this.emailService.sendContactUsMessage(message).subscribe(
            () => {
                this.inProgress = false;
                this.completed = true;
                this.dialogRef.close(true)
            },
            (error:any) => {
                this.inProgress = false;
                this.completed = false;
                this.error = error;
            }
        );
    }

    close() {
        this.dialogRef.close(false)
    }
}
