import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, catchError, map, Observable,tap, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";

export enum SupportedLocale {
  EN='en',
  JA='ja',
  FR='fr'
}
export type Locale = {
  locale: SupportedLocale,
  tokens: Token[]
}
export type Token = {
  id: string,
  value: string
}
export const DEFAULT_LOCALE = SupportedLocale.FR;
export const REGIS_CURRENT_LOCALE = "QUANSIC_CURRENT_LOCALE";

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  tokens: Token[] = []
  http = inject(HttpClient)
  currentLocale$: BehaviorSubject<SupportedLocale|null> = new BehaviorSubject<SupportedLocale|null>(null);

  getIsoLocale(): string {
    const locale = this.readCurrentLocale()
    if(locale === SupportedLocale.EN) return 'en-US';
    else if(locale === SupportedLocale.JA) return 'ja-JP';
    else if(locale === SupportedLocale.FR) return 'fr-FR';
    else return locale
  }

  isLocaleSupported(locale: string): boolean {
    return [SupportedLocale.EN.toString(), SupportedLocale.JA.toString(), SupportedLocale.FR.toString()].includes(locale)
  }

  public loadDefaultLocale(): Observable<SupportedLocale>{
    return this.loadLocale(this.readCurrentLocale())
  }

  public loadLocale(locale: SupportedLocale): Observable<SupportedLocale>{
    return this.getTokens$(locale);
  }

  public localize(id: string): string { 
    return this.tokens?.find(t => t.id === id)?.value || id
  }

  public isLocalizationToken(locToken: string): boolean{
    return this.tokens.some(t => t.id === locToken);
  }

  private getTokens$(locale: string): Observable<SupportedLocale> {
    if(!this.isLocaleSupported(locale)) return throwError(() => new Error(`Unsupported Locale ${locale}`))
   
    return this.http.get<Locale>(`/assets/locales/messages.${locale}.json`).pipe(
        tap(localeJson => console.log("localeJson", localeJson)),
        map(localeJson => localeJson.tokens || []),
        tap(tokens => this.tokens = tokens),
        tap(tokens => console.log(locale, tokens)),
        map(() => locale as SupportedLocale),
        tap(locale => this.currentLocale$.next(locale)),
        tap(locale => this.saveCurrentLocale(locale)),
        catchError((error) => {
          console.log(`Failed to load localization file messages.${locale}.json`, error)
          return throwError(() => new Error(error))
        })
    )
  }

  saveCurrentLocale(locale: SupportedLocale): void {
    return localStorage.setItem(REGIS_CURRENT_LOCALE, locale)
  }

  readCurrentLocale(): SupportedLocale {
    return (localStorage.getItem(REGIS_CURRENT_LOCALE) || DEFAULT_LOCALE) as SupportedLocale
  }
}