import { LocalizationService } from './localization.services';
import { Directive, ElementRef, inject, Input, OnInit, Renderer2 } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[loc]',
  standalone: true
})
export class LocalizationDirective implements OnInit {
  @Input() loc = '';

  localizationService = inject(LocalizationService)
  elem = inject(ElementRef)
  renderer = inject(Renderer2)
  domSanitizer = inject(DomSanitizer)

  ngOnInit() {
    const locText = this.localizationService.localize(this.loc)
    // console.log("locText", locText);
    return this.renderer.setProperty(this.elem.nativeElement, 'textContent', locText) 
  }
}